.table_container {
    border-radius: 16px;
}

.table_header > th > div,
.table_header > th > span {
    color: #00205b !important;
    font-weight: bold !important;
}

.table_header > th {
    border-bottom: 3px solid #00205b !important;
}

.table_container {
    box-shadow: none !important;
    overflow-x: hidden !important;
}

.table_container > table > tbody > tr {
    border-collapse: separate;
}

.data_row {
    color: white;
    border: 1px solid #e9e4e0;
    box-sizing: border-box;
    border-radius: 4px;
    background: #ffffff;
}

.data_row_open {
    color: white;
    margin-bottom: 5%;
    background-color: #00205b;
    border: 3px solid #00205b;
}

.data_cell_bold {
    color: white !important;
    font-weight: bold !important;
}

.font_weight_bold {
    font-weight: bold !important;
}

.color_white {
    color: white !important;
}
.color_red {
    background-color: #eb2448 !important;
}

.color_blue {
    background-color: #61b7e8 !important;
}

.color_orange {
    background-color: #ff9232 !important;
}
.table_row {
    border-bottom: 1px solid lightgrey !important;
}

.sub_row_app {
    width: 48% !important;
    font-weight: bold !important;
    vertical-align: top !important;
}

.sub_row_sub_type {
    font-weight: bold !important;
}
.subdirectory_arrow_right {
    color: lightgrey;
    width: 2em;
    height: 2em;
    margin: 0 0.5em -0.5em -0.5em;
}

.app_version_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 500px;
}

.app_version {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.component_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 18px;
}
