.role-page {
    flex: 2 0 0;
}

.role-page-container {
    margin: 2% 10% 1% 10%;
    height: 90%;
}

.role-page-sub-container {
    display: flex;
    width: 40%;
    position: relative;
    margin-left: auto;
    margin-right: 0%;
    justify-content: space-between;
}

.role-name {
    color: #002466 !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 28px;
    margin-top: 6%;
    height: 100%;
}

.role-users-number {
    color: #063b9e !important;
    text-decoration: underline;
    font-family: roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-top: 1%;
    cursor: pointer;
    margin: 1rem 0 2rem;
}

.MuiDrawer-root {
    width: 10% !important;
}

.role-permission-divider {
    color: #063b9e !important;
}

.drawer {
    width: 100%;
}

.role-user-container {
    display: flex;
    flex-direction: row;
    margin-left: 7%;
    margin-right: 7%;
}

.role-user-sub-container {
    width: 50%;
    display: table-row;
}

.role-user-dialog {
    width: 50%;
}

.role-details-container {
    margin-top: 3%;
}

.role-user-details-container {
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    height: 100%;
}

.role-select-container {
    width: 60%;
}

.role-select-component {
    color: #063b9e !important;
    font-size: 20px !important;
    font-family: Roboto !important;
    line-height: 24px !important;
    font-weight: 700 !important;
}

.role-permission-container {
    margin: 3% 7% 0% 7%;
    height: 80%;
    position: relative;
    overflow: hidden;
}

.permissions-title {
    color: #063b9e;
    font-family: roboto;
    font-size: 16px;
    font-weight: 700;
    width: 90px;
    height: 10%;
}

.checkbox-group {
    width: 100%;
    height: 90%;
}

.header-cell {
    color: #282e3a;
    font-family: roboto;
    font-size: 14px;
    font-weight: 700;
}

.display-no-role {
    color: #979797;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    margin-top: 32px;
    font-family: roboto;
}

.permissionTable div[class*='ds-datatable'][class*='-head'] {
    display: none;
}

.checkboxGroup div[class*='ds-datatable'][class*='-container'] {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.checkboxGroup div[class*='ds-datatable'][class*='-container'] div[class*='ds-datatable'][class*='-pagination'] {
    height: 10%;
}

v {
    background-color: white !important;
    border-bottom: 1px solid #e0e3e9;
}

[class*='ds-datatable'][class*='-body'] {
    height: 100% !important;
}
