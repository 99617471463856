.aircraft-card-checkbox label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #282e3a;
    align-items: center;
}

.aircraft-card-checkbox > input:checked + label:after {
    top: 3px !important;
}
