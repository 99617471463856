.dialog-footer {
    text-align: right;
    background-color: #00205b;
    position: sticky;
    width: 100%;
}
.custom-tooltip {
    background-color: #ffffff;
    border: 1px solid #666;
    color: #333;
    width: 200px;
}
.custom-tooltip-target {
    border-radius: 8px;
    padding: 0px;
}
.custom-error {
    font-size: 16px;
    margin-left: 8px;
    transform: translateY(10px);
    color: #00205b;
}
.custom-error.error {
    color: #f00;
}
.input-label {
    font-size: 13px;
    line-height: 16px;
    font-weight: medium;
    margin: 12px 0 8px;
}
.technical-name-input {
    display: flex;
    align-items: flex-start;
}
.role-input {
    height: 24px;
    margin: 0;
    padding: 2rem 0;
}
