.aircraft_container {
    width: 100%;
    background-color: #fafbfc;
    min-height: calc(100vh - 3rem - 6em);
    display: flex;
    flex-direction: column;
}

.aircraft_content_container {
    display: flex;
    flex: 1;
    margin: 2%;
    position: relative;
    height: 100%;
}
