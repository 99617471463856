.banner {
    width: 35%;
    top: 6%;
    left: 50%;
    position: fixed !important;
    transform: translateX(-50%);
}

.title {
    font-weight: bold;
}

.description {
    margin: 0 5% 0 5%;
}
