.page {
    flex: 2 0 0;
}

.page_container {
    margin: 2% 10% 1% 10%;
    height: 90%;
}

.page_sub_container {
    display: flex;
    width: 40%;
    position: relative;
    margin-left: auto;
    margin-right: 0%;
    justify-content: space-between;
}

.temp {
    padding-right: 30px;
}

.add_company_button {
    padding: 0 30px;
}
