.copy_text {
    cursor: pointer;
    height: 100%;
    width: 100%;
}

.vpn_key {
    color: #002466 !important;
    display: flex !important;
    width: 100% !important;
}
