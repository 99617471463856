.container {
    display: inline-block;
    width: 100%;
}

.left {
    float: left;
}

.right {
    float: right;
}
