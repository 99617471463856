.loading_button {
    text-transform: capitalize !important;
    font-weight: 700 !important;
    line-height: 1.5rem !important;
    color: #ffffff !important;
    border-top-width: 1px !important;
    border-right-width: 1px !important;
    border-bottom-width: 1px !important;
    border-left-width: 1px !important;
    border-top-style: solid !important;
    border-right-style: solid !important;
    border-bottom-style: solid !important;
    border-left-style: solid !important;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-image-source: initial !important;
    border-image-slice: initial !important;
    border-image-width: initial !important;
    border-image-outset: initial !important;
    border-image-repeat: initial !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    box-shadow: none !important;
    padding-top: calc(0.5rem - 1px) !important;
    padding-right: 1rem !important;
    padding-bottom: calc(0.5rem - 1px) !important;
    padding-left: 1rem !important;
}

.loading_button:disabled {
    opacity: 0.4 !important;
}

.primary {
    background-color: #002d80 !important;
}

.primary:hover {
    background-color: #002466 !important;
}

.success {
    background-color: #08875b !important;
}

.success:hover {
    background-color: #097350 !important;
}

.error {
    background-color: #e4002b !important;
}

.error:hover {
    background-color: #b60022 !important;
}
