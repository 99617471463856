.container {
    height: 342px;
    width: 532px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.nav {
    width: 180px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #002466;
}

.nav_item {
    background: inherit;
    color: black;
    height: 48px;
    border: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    padding-left: 16px;
    cursor: pointer;
}

.nav_item.active {
    background: #002466;
    color: white;
}

.page {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 24px 24px 0 24px;
}

.page_header {
    display: flex;
    flex-direction: column;
}

.page_header_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #063b9e;
    margin: 5px 0 0 0;
}

.page_header_subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8390a7;
    margin: 0 0 10px 0;
}

.hidden {
    visibility: hidden;
}

.line {
    border: 1px solid #e0e3e9;
}

.page_body {
    flex: 1;
    margin: 16px 0;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* width */
.page_body::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.page_body:-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.page_body::-webkit-scrollbar-thumb {
    background: #e0e3e9;
    border-radius: 4px;
}

/* Handle on hover */
.page_body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.apply {
    background: #e0e3e9;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.16);
    height: 64px;
    padding: 16px 28px;
    display: flex;
    flex-direction: row-reverse;
}

.apply_button {
    margin: 0 8px;
}
