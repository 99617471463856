/* TODO : Is it still used ? */
.table-container {
    flex: 2 0 0;
}

.generic-table-container {
    margin: 2% 10% 1% 10%;
}

/* New  */
.generic-table {
    margin-top: 3%;
}

.generic-table div div div[class*='ds-datatable'][class*='-head'] {
    align-items: center;
    background-color: inherit;
}

.generic-table div div div[class*='ds-datatable'][class*='-head'] {
    align-items: center;
    background-color: inherit;
}

.generic-table div div div[class*='ds-datatable'][class*='-head'] div[class*='ds-datatable'][class*='-row'] {
    height: 4rem;
    background-color: inherit;
    border-bottom: 3px solid #002d80;
}

.generic-table
    div
    div
    div[class*='ds-datatable'][class*='-head']
    div[class*='ds-datatable'][class*='-row']
    div[class*='ds-datatable'][class*='-cell--head'] {
    color: #00205b !important;
    font-weight: 700;
}

.generic-table
    div
    div
    div[class*='ds-datatable'][class*='-head']
    div[class*='ds-datatable'][class*='-row']
    div[class*='ds-datatable'][class*='-cell--head']
    div {
    color: #00205b !important;
    font-weight: 700;
}

.generic-table div div div[class*='ds-datatable'][class*='-body'] {
    height: 100% !important;
}

.generic-table div div div[class*='ds-datatable'][class*='-body'] div[class*='ds-datatable'][class*='-row'] {
    background-color: inherit;
    border-top: 1px solid #e0e3e9;
    min-height: 50px;
}

.generic-table div div div[class*='ds-datatable'][class*='-body'] div[class*='ds-datatable'][class*='-row']:hover {
    background-color: #e0e3e993;
}
