.header {
    position: fixed !important;
    height: 3.5rem;
}

.header-select {
    width: auto !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.header-select > option {
    color: black;
}

a::before {
    border-bottom: none !important;
}

a:-webkit-any-link {
    text-decoration: none;
}
