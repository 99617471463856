.page {
    flex: 2 0 0;
}

.page_container {
    margin: 0 4%;
    display: grid;
    grid-template-columns: 36% 32% 32%;
    height: 100%;
}

.container_card {
    display: flex;
    height: 100%;
    border: 1px solid #c1c7d3;
    border-radius: 8px;
    flex-direction: column;
    padding: 24px;
    margin: 0 24px;
}

.container_header {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.container_title {
    color: #063b9e;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
    /* text-align: center; */
}

.container_subtitle {
    color: #8390a7;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin: 4px 0 10px 0;
}

.line {
    border: 1px solid #002466;
}

.container_body {
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 1;
}

/* width */
.container_body::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.container_body:-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.container_body::-webkit-scrollbar-thumb {
    background: #e0e3e9;
    border-radius: 4px;
}

/* Handle on hover */
.container_body::-webkit-scrollbar-thumb:hover {
    background: #555;
}
