.content-applications {
    margin: 10.75% 0 10.8% 0;
}

.content-applications > svg {
    height: 10rem;
    width: 10rem;
    color: lightgrey;
}

.center {
    text-align: center;
    width: 100%;
}

#title {
    color: #545962;
    font-weight: bold;
}

#content {
    color: grey;
}

.table-header > th > div,
.table-header > th > span {
    color: #00205b !important;
    font-weight: bold !important;
}

.table-header > th {
    border-bottom: 3px solid #00205b !important;
}

.table-container {
    box-shadow: none !important;
    overflow-x: hidden !important;
}

.table-container > table > tbody > tr {
    border-collapse: separate;
}

.bold-label {
    font-weight: bold !important;
}

.unbold-label {
    font-weight: normal !important;
}

/*.MuiTableRow-root css-2blr9s-MuiTableRow-root{
     margin-top: 5% !important
 }*/

.data-row {
    border: 1px solid #e0e3e9;
    box-sizing: border-box;
    border-radius: 4px;
    background: #ffffff;
}

.css-1ex1afd-MuiTableCell-root {
    border-bottom: none !important;
}

.admin-container {
    background-color: #fafbfc;
    width: 100%;
}

.admin-content-container {
    display: flex;
    margin: 2%;
    position: relative;
    height: auto;
}

.apps-table {
    min-width: 375px;
    margin: 2% auto;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
