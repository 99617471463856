.footer-header {
    height: 60%;
}

.footer-icon {
    transform: rotate(90deg);
}

.back-to-top {
    position: fixed;
    text-align: right;
    right: 0;
    bottom: 0;
    width: 5%;
    margin-left: auto;
    margin-right: 8px;
    margin-bottom: 8px;
    z-index: 1000;
}

.back-to-top > button,
.back-to-top > button::after {
    border-color: transparent !important;
    padding-right: 8px;
}
