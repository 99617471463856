.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    max-height: 460px;
    background-color: white;
    padding: 1rem;
    overflow: auto;
    border-radius: 4px;
}
