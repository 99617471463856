.page {
    flex: 2 0 0;
}

.page_container {
    margin: 0 8%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ac_card {
    height: 120px;
    width: 160px;
}

.card_content {
    padding: 8px !important;
}

.card_remote_loading {
    height: 15px;
}

.card_remote_loading_tooltip_container {
    float: right;
    width: 15px;
}

.card_remote_loading_tooltip {
    max-width: 250px;
}

.card_remote_loading_tooltip_text {
    margin: 0;
    padding: 0;
}

.card_plane {
    display: block;
    margin: auto;
    width: 133px;
}

.card_tail_id {
    color: #002466;
    font-weight: 700;
    line-height: 20px;
    font-size: 14px;
    height: 20px;
    margin: 0;
    padding: 0;
}

.card_company {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #a3d4f0;
    border-radius: 12px;
    padding: 0px 8px;
    height: 16px;
    margin-top: 6px;
}

.card_company_text {
    font-weight: 500;
    line-height: 20px;
    color: #282e3a;
}

.emptyList {
    width: 100%;
    text-align: center;
    margin-top: 10vh;
    font-size: 24px;
    color: gray;
}

.search_container {
    margin-bottom: 10px;
}

.search {
    float: right;
    display: flex;
}

.search_input {
    width: 200px;
    height: 33px;
}

.filter_button {
    margin-left: 20px;
}
