.p-6 {
    padding: 2.5rem 6rem 6rem 6rem;
}

.p-title {
    padding: 2% 2% 2% 2%;
}

.p-bottom {
    margin-bottom: 5%;
}

.bg-primaryblue-90 {
    background-color: #00205b;
}

.text-left {
    text-align: left;
}

.flex-container {
    display: flex;
}

.flex-item {
    flex: 50%;
}

.link {
    margin-top: 3%;
}

.tab-content {
    width: 100%;
    text-align: left;
    padding: 3rem 10rem 1rem 10rem;
}

.tab-content > button,
.tab-content > a {
    position: inherit;
}

.m-divider {
    margin: 0 5% 0 5% !important;
}

.margin-button {
    margin-top: 3% !important;
}

.margin-button-right {
    margin-top: 3% !important;
    margin-left: 1% !important;
}

.blue-text {
    text-decoration: underline;
    cursor: pointer;
    color: #063b9e;
}

.white-input {
    text-overflow: ellipsis;
    color: grey !important;
    background-color: white !important;
}

.dark-input-icon {
    color: #00205b !important;
}

.menu-item {
    background-color: transparent !important;
}

.login-content {
    width: 100%;
    text-align: left;
    padding: 3rem 25rem 1rem 5rem;
}
