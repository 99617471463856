.page_sub_container {
    width: 20%;
    float: right;
    margin-bottom: 20px;
}

.validation_center_table {
    width: 75%;
    margin: auto;
}

.spinner {
    margin-top: 40vh !important;
    margin-left: 50% !important;
    transform: translateX(-50%) !important;
}

.iconHeight {
    height: 15px !important;
}

.emptyList {
    width: 100%;
    text-align: center;
    margin-top: 20vh;
    font-size: 24px;
    color: gray;
}
