.page {
    flex: 2 0 0;
}

.page_container {
    margin: 2% 10% 1% 10%;
    height: 90%;
}

/* TODO : Is it still used ? */
.table-container {
    flex: 2 0 0;
}

.generic-table-container {
    margin: 2% 10% 1% 10%;
}

/* New  */
.generic_table {
    margin-top: 3%;
}

.no_apps {
    margin: 10.75% 0 10.8% 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no_apps > svg {
    height: 10rem;
    width: 10rem;
    color: lightgrey;
}

.noapps_title {
    color: #545962;
    font-weight: bold;
}

.noapps_content {
    color: grey;
}

.tooltip {
    width: 45px;
}

.generic_table div div div[class*='ds-datatable'][class*='-head'] {
    align-items: center;
    background-color: inherit;
}

.generic_table div div div[class*='ds-datatable'][class*='-head'] {
    align-items: center;
    background-color: inherit;
}

.generic_table div div div[class*='ds-datatable'][class*='-head'] div[class*='ds-datatable'][class*='-row'] {
    height: 4rem;
    background-color: inherit;
    border-bottom: 3px solid #002d80;
}

.generic_table
    div
    div
    div[class*='ds-datatable'][class*='-head']
    div[class*='ds-datatable'][class*='-row']
    div[class*='ds-datatable'][class*='-cell--head'] {
    color: #00205b !important;
    font-weight: 700;
}

.generic_table
    div
    div
    div[class*='ds-datatable'][class*='-head']
    div[class*='ds-datatable'][class*='-row']
    div[class*='ds-datatable'][class*='-cell--head']
    div {
    color: #00205b !important;
    font-weight: 700;
}

.generic_table div div div[class*='ds-datatable'][class*='-body'] {
    height: 100% !important;
}

.generic_table div div div[class*='ds-datatable'][class*='-body'] div[class*='ds-datatable'][class*='-row'] {
    background-color: inherit;
    border-top: 1px solid #e0e3e9;
    min-height: 50px;
}

.generic_table div div div[class*='ds-datatable'][class*='-body'] div[class*='ds-datatable'][class*='-row']:hover {
    background-color: #e0e3e993;
}
