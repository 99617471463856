.search {
    padding-top: 15px;
    padding-left: 2px;
    display: flex;
    justify-content: space-between;
}

.search_input {
    width: 75%;
}

.filter_button_container {
    width: 20%;
    align-items: center;
}

.filter_button {
    float: right;
}

.all_aircrafts_title_container {
    padding-left: 40px;
    margin-bottom: 24px;
    margin-top: 12px;
    border-bottom: 1px solid #e0e3e9;
}

.all_aircrafts_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #282e3a;
}
