.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.chose_app {
    display: flex;
}

.chose_app_combobox {
    width: 80%;
    padding-left: 2px;
}

.chose_app_trash {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
}

.button_remove {
    border: none;
    background-color: inherit;
    cursor: pointer;
}

.chose_app_version_combobox {
    margin-top: 24px;
    width: 60%;
    padding-left: 2px;
}
