.back_button {
    width: 100%;
    min-width: 480px;
    text-align: left;
    padding: 16px 16px 16px 0;
}
.page {
    display: flex;
    flex-direction: column !important;
    gap: 62px;
    flex: 2 0 0;
}

.page_container {
    margin: 0 10% 5% 0%;
    height: 90%;
}

.page_container_history_table {
    height: 90%;
}

.page_flex_container {
    width: 94%;
    margin: auto !important;
}

.ac_card {
    box-sizing: border-box;

    width: 208px;
    border: 1px solid #e0e3e9;
    border-radius: 6.46341px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.card_content {
    padding: 32px 8px 8px 8px !important;
    position: relative;
}

.card_remote_loading {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 15px;
}

.card_remote_loading_tooltip_container {
    float: right;
    width: 15px;
}

.card_remote_loading_tooltip {
    max-width: 250px;
}

.card_remote_loading_tooltip_text {
    margin: 0;
    padding: 0;
}

.card_plane {
    display: block;
    margin: auto;
    width: 100%;
}

.card_tail_id {
    color: #002466;
    font-weight: 700;
    line-height: 20px;
    font-size: 14px;
    height: 20px;
    margin: 0;
    padding: 0;
}

.card_company {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #a3d4f0;
    border-radius: 12px;
    padding: 0px 8px;
    height: 16px;
    margin-top: 6px;
}

.card_company_text {
    font-weight: 500;
    line-height: 20px;
    color: #282e3a;
}

.card_devider {
    margin-top: 22px;
    border: 1px solid #002d80;
}
.card_aircraft_data_labels {
    color: #647592;
}

.card_aircraft_data {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #063b9e;
}

.card_icao_tailNumber_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.emptyList {
    width: 100%;
    text-align: center;
    margin-top: 10vh;
    font-size: 24px;
    color: gray;
}

.search_container {
    margin-bottom: 10px;
}

.select_container {
    display: flex;
    margin-left: auto;
    margin-bottom: 2%;
    width: 200px;
    height: 33px;
}

.filter_button {
    margin-left: 20px;
}
.aircraft_select_container {
    display: flex;
    width: auto;
    margin-left: auto;
    margin-bottom: 16px;
}

.aircraft_configuration_container {
    width: 100%;
    background-color: #fafbfc;
    min-height: calc(100vh - 3rem - 6em);
    display: flex;
    flex-direction: column;
}

.aircraft_configuration_content_container {
    display: flex;
    flex-direction: column;
    margin: 2% 4% 2% 4%;
    height: 100%;
}

.aircraft_configuration_sub_content_container {
    display: flex;
    flex-direction: row;
    gap: 62px;
}
.aircraft_configuration_sub_sub_content_container {
    display: flex;
    flex-direction: column !important;
    width: 100%;
}

.history_deployments_table {
    min-width: 375px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.currAppContentTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #002466;
    margin-right: 15%;
}
.current_conf_deployments_table {
    min-width: 375px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
