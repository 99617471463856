.content_deployments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content_deployments > svg {
    height: 10rem;
    width: 10rem;
    color: lightgrey;
}
