body {
    background-color: #fffbfb;
}

.text-left {
    text-align: left;
}

.app-body {
    padding-top: 3.5rem;
    padding-bottom: 6em;
    background-color: #fafbfc;
    min-height: 100vh;
}

.main-page-title {
    padding: 1.5rem 3rem 1rem 3rem;
}
