.sync_title_container {
    display: flex;
    flex-direction: column;
}

.sync_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #001b4d;
}

.sync_title_input_container {
    margin: 0 2px 10px 2px;
}

.sync_size_container {
    display: flex;
    flex-direction: column;
}

.sync_data_title {
    margin: 5px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #001b4d;
}

.sync_data_value {
    margin: 10px 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #063b9e;
}

.option_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.option_toggles_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.option_toggle {
    margin: 5px 0;
}

.deploy_button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
